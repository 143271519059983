export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106')
];

export const server_loads = [];

export const dictionary = {
		"/": [18],
		"/(user)/(organizer)/(admin)/admin": [23,[2,3,4,6]],
		"/(user)/chats": [30,[2]],
		"/(user)/chats/scheduled": [32,[2]],
		"/(user)/chats/schedule": [31,[2]],
		"/(user)/circles": [33,[2]],
		"/(user)/circles/create": [45,[2]],
		"/(user)/circles/[id]": [34,[2,8]],
		"/(user)/circles/[id]/announcements": [35,[2,8]],
		"/(user)/circles/[id]/dashboard": [36,[2,8,9]],
		"/(user)/circles/[id]/dashboard/events": [37,[2,8,9]],
		"/(user)/circles/[id]/dashboard/new-connections": [38,[2,8,9]],
		"/(user)/circles/[id]/dashboard/new-members": [39,[2,8,9]],
		"/(user)/circles/[id]/documents": [40,[2,8]],
		"/(user)/circles/[id]/events": [41,[2,8]],
		"/(user)/circles/[id]/events/create": [42,[2]],
		"/(user)/circles/[id]/following": [43,[2,8]],
		"/(user)/circles/[id]/members": [44,[2,8]],
		"/(user)/(organizer)/(admin)/(super-admin)/companies": [20,[2,3,4,5]],
		"/(user)/(organizer)/(admin)/(super-admin)/companies/add": [21,[2,3,4,5]],
		"/(user)/(organizer)/(admin)/(super-admin)/companies/curate-images": [22,[2,3,4,5]],
		"/(user)/(super-admin)/companies/[name]/settings": [28,[2]],
		"/(user)/(super-admin)/companies/[name]/users/[role]": [29,[2]],
		"/(user)/connections": [46,[2,10]],
		"/(user)/connections/all-connections": [47,[2,10]],
		"/(user)/connections/events": [48,[2,10]],
		"/(user)/connections/favorites": [49,[2,10]],
		"/(user)/create-account": [50,[2,11]],
		"/(user)/(organizer)/dashboard": [24,[2,3,7]],
		"/(user)/(organizer)/dashboard/events": [25,[2,3,7]],
		"/(user)/(organizer)/dashboard/new-connections": [26,[2,3,7]],
		"/(user)/(organizer)/dashboard/new-members": [27,[2,3,7]],
		"/(user)/discover": [51,[2,12]],
		"/(user)/discover/all": [52,[2,12]],
		"/(user)/discover/courses": [53,[2,12]],
		"/(user)/discover/near-you": [54,[2,12]],
		"/(user)/discover/new": [55,[2,12]],
		"/(user)/discover/posts": [56,[2,12]],
		"/(user)/discover/recommended": [57,[2,12]],
		"/(user)/discover/searchResults": [58,[2,12]],
		"/(anonymous)/eventprompts/[id]": [19],
		"/(user)/events": [59,[2]],
		"/(user)/events/all": [61,[2,13]],
		"/(user)/events/all/calendar": [62,[2,13]],
		"/(user)/events/all/past": [63,[2,13]],
		"/(user)/events/all/upcoming": [64,[2,13]],
		"/(user)/events/create": [65,[2]],
		"/(user)/events/[id]": [60,[2]],
		"/(user)/home": [66,[2,14]],
		"/(user)/home/announcements": [67,[2,14]],
		"/(user)/home/documents": [68,[2,14]],
		"/(user)/home/events": [69,[2,14]],
		"/(user)/home/events/create": [70,[2]],
		"/(user)/home/following": [71,[2,14]],
		"/(user)/livestream/join": [72,[2]],
		"/(user)/livestream/welcome": [73,[2]],
		"/(user)/logged-out": [74,[2]],
		"/(user)/organization": [~75,[2,15]],
		"/(user)/organization/interests": [77,[2,15]],
		"/(user)/organization/skills": [78,[2,15]],
		"/(user)/organization/values": [79,[2,15]],
		"/(user)/organization/[view]": [76,[2,15]],
		"/(user)/profile": [80,[2]],
		"/(user)/profile/home": [81,[2]],
		"/(user)/profile/interests": [82,[2]],
		"/(user)/profile/languages": [83,[2]],
		"/(user)/profile/location": [84,[2]],
		"/(user)/profile/readyToMentorSkills": [85,[2]],
		"/(user)/profile/skills": [86,[2]],
		"/(user)/profile/wantToLearnSkills": [87,[2]],
		"/(user)/scheduled": [88,[2]],
		"/(user)/scheduled/join": [89,[2]],
		"/(user)/scheduled/new-videocall": [90,[2]],
		"/(user)/settings": [91,[2,16]],
		"/(user)/spotlight": [92,[2]],
		"/(user)/talent": [93,[2,17]],
		"/(user)/talent/[view]": [94,[2,17]],
		"/(user)/teams": [95,[2]],
		"/(user)/user/[userId]": [96,[2]],
		"/(user)/videocycle": [97,[2]],
		"/(user)/videocycle/break": [98,[2]],
		"/(user)/videocycle/chapter": [99,[2]],
		"/(user)/videocycle/done": [100,[2]],
		"/(user)/videocycle/join": [101,[2]],
		"/(user)/videocycle/new-videocall": [102,[2]],
		"/(user)/videocycle/quiz": [103,[2]],
		"/(user)/videocycle/review": [104,[2]],
		"/(user)/videocycle/waiting": [105,[2]],
		"/(user)/videocycle/welcome": [106,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';